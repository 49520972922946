import { styled } from '@stitches/react'
import NextImage from 'next/image'
import ErrorImg from '../../../public/assets/images/erro.svg'
import { Link } from '../Link'
import { Text, Title } from '../Typography'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
  padding: '16px',
  gap: '32px',

  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    textAlign: 'center',

    h1: {
      color: '$neutral20',
    },
  },
})

export function NotFound() {
  return (
    <Container>
      <NextImage src={ErrorImg} alt="" width={165.9} height={250} />
      <div>
        <Title as="h1" size="headline6">
          404 - Página não encontrada
        </Title>
        <Text size="body3">
          Ops! Página não encontrada. Verifique o endereço ou{' '}
          <Link href="https://flashapp.com.br">
            volte para a página da Flash.{' '}
          </Link>
        </Text>
      </div>
    </Container>
  )
}
